/* eslint-disable no-sequences */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose, withErrorBoundary } from '@concur/core-ui-shell';
import { CONCUR_THEMES_BRANDING_ID } from '@concur/core-ui-shell/lib/constants';
import AskQuestionMenuItem from './_AskQuestionMenuItem';
import LanguageDropdown from './_LanguageDropdown';
import Logo from '../Logo/Logo';
import Menu from './_Menu';
import MenuItem from './_MenuItem';
import Navigation from './_Navigation';
import ProfileMenuItem from './_ProfileMenuItem';
import ThemeSwitcher from './_ThemeSwitcher';
import AppHeaderContext from './_AppHeaderContext';
import HelpPortalMenuItem from './_HelpPortalMenuItem';
import DigitalAssistantMenuItem from './_DigitalAssistantMenuItem';
import TranslationToolMenuItem from './_TranslationToolMenuItem';
import useModifiedHelpMenu from '../utils/useModifiedHelpMenu';
import * as styles from './AppHeader-*.css';

const CSS_BLOCK = 'sapcnqr-app-header';
const PRIMARY_MENU_ITEM_COUNT_THRESHOLD = 3;

const getPrimaryMenuItemCount = (props) => {
    const {
        menuData,
    } = props;

    const {
        homeMenuItem,
        testDriveMenuItem,
        applicationMenuItems,
    } = menuData;

    let count = 0;
    count += homeMenuItem ? 1 : 0;
    count += testDriveMenuItem ? 1 : 0;
    count += applicationMenuItems ? applicationMenuItems.length : 0;

    return count;
};

const buildUtilityMenu = (props, primaryMenuItemCount, helpPortalLoaded) => {
    const {
        menuData,
    } = props;

    const {
        adminMenuItem,
        helpMenuItem,
        linksMenuItem,
        askQuestionMenuItem,
        subscribeMenuItem,
        supportMenuItem,
        localizationToolMenuItem,
    } = menuData;

    const menuItems = [];

    if (subscribeMenuItem) {
        menuItems.push({
            ...subscribeMenuItem,
            anchorClassName: `${CSS_BLOCK}__anchor--tertiary`,
        });
    }

    if (adminMenuItem && primaryMenuItemCount > PRIMARY_MENU_ITEM_COUNT_THRESHOLD) {
        menuItems.push(adminMenuItem);
    }

    if (linksMenuItem && !adminMenuItem && primaryMenuItemCount > PRIMARY_MENU_ITEM_COUNT_THRESHOLD) {
        menuItems.push(linksMenuItem);
    }

    if (supportMenuItem) {
        menuItems.push(supportMenuItem);
    }

    if (helpMenuItem && !helpPortalLoaded) {
        let helpSubMenuItems = helpMenuItem.subItems || [];
        if (localizationToolMenuItem) {
            helpSubMenuItems = helpSubMenuItems.concat(localizationToolMenuItem);
        }

        menuItems.push({
            ...helpMenuItem,
            subItems: helpSubMenuItems,
        });
    }

    if (askQuestionMenuItem) {
        menuItems.push({
            ...askQuestionMenuItem,
            'data-id': 'question',
        });
    }

    return menuItems;
};

const GatewayHeader = (props) => {
    const {
        actingAs,
        brandingId,
        classNameMap,
        formatter,
        isGov,
        isLoggedIn,
        isRetiredBrand,
        menuData,
        showLanguageDropdown,
        stretchContent,
        useFullWidth,
        useSmallViewportBreakpoints,
        // eslint-disable-next-line no-unused-vars
        onEndSession,
        // eslint-disable-next-line no-unused-vars
        onStartSession,
        // eslint-disable-next-line no-unused-vars
        logoutUser,
    } = props;

    const {
        homeMenuItem,
        applicationMenuItems,
        profileMenuItem,
        adminMenuItem,
        linksMenuItem,
        testDriveMenuItem,
        pageMenuItems,
        noMenuDataFallback,
    } = menuData;

    const [utilityWidth, setUtilityWidth] = useState(0);
    const [appHeaderWidth, setAppHeaderWidth] = useState(0);
    const [helpPortalLoaded, setHelpPortalLoaded] = useState(false);
    const [digitalAssistantLoaded, setDigitalAssistantLoaded] = useState(false);
    const [translationToolLoaded, setTranslationToolLoaded] = useState(false);

    const { isLocalizationUser } = useContext(AppHeaderContext) || {};

    const classnames = classnamesBind.bind(classNameMap);

    const {
        employeeDisplayName,
    } = useContext(AppHeaderContext) || {};

    const primaryMenuItemCount = getPrimaryMenuItemCount(props);
    const utilityMenuItems = buildUtilityMenu(props, primaryMenuItemCount, helpPortalLoaded);

    const contentRef = React.createRef();
    const utilityRef = React.createRef();

    const classes = classnames(
        CSS_BLOCK,
        {
            [`${CSS_BLOCK}--full-width`]: useFullWidth,
            [`${CSS_BLOCK}--fully-responsive`]: useSmallViewportBreakpoints,
        },
    );

    const primaryContentClasses = classnames(
        `${CSS_BLOCK}__content`,
        `${CSS_BLOCK}__content--primary`,
        { [`${CSS_BLOCK}__content--stretch`]: stretchContent },
    );

    const secondaryContentClasses = classnames(
        `${CSS_BLOCK}__content`,
        `${CSS_BLOCK}__content--secondary`,
        { [`${CSS_BLOCK}__content--stretch`]: stretchContent },
    );

    const primaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--primary`,
    );

    const utilityNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--utility`,
        {
            [`${CSS_BLOCK}__nav--utility-no-menu-data`]: utilityMenuItems.length === 0,
        },
    );

    const secondaryNavClasses = classnames(
        `${CSS_BLOCK}__nav`,
        `${CSS_BLOCK}__nav--secondary`,
    );

    const homeIconClasses = classnames(
        `${CSS_BLOCK}__home-icon`,
        {
            [`${CSS_BLOCK}__home-icon--gov`]: isGov,
            [`${CSS_BLOCK}__home-icon--retired`]: isRetiredBrand,
        },
    );

    const getWidth = (ref) => ref?.current?.offsetWidth || 0;

    const handleResize = () => {
        setUtilityWidth(getWidth(utilityRef));
        setAppHeaderWidth(getWidth(contentRef));
    };
    // runs only on load - this gets us our default utility and container widths
    useEffect(handleResize, []);

    // runs on every resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useModifiedHelpMenu({
        menuData,
        translationToolLoaded,
        helpPortalLoaded,
    });

    const renderUtilityMenuItem = (item, index) => {
        if (item['data-id'] === 'question') {
            return (
                <AskQuestionMenuItem
                    {...item}
                    classNameMap={classNameMap}
                    cssBlock={CSS_BLOCK}
                    key={`utility${index}`}
                />
            );
        }

        return (
            <MenuItem
                {...item}
                classNameMap={classNameMap}
                cssBlock={CSS_BLOCK}
                hasPopover
                isSubItemMenuAlignRight
                key={`utility${index}`}
            />
        );
    };

    return (
        <>
            <div
                className={classnames(classes, `${CSS_BLOCK}--primary`)}
                data-username={employeeDisplayName || ''}
                role="banner"
            >
                {isLoggedIn && (
                    <a
                        className={classnames(`${CSS_BLOCK}__screen-reader-only`)}
                        href="#cnqr-app-content"
                    >
                        {formatter.formattedMessage({ id: 'CoreUI.skipNavigation' })}
                    </a>
                )}
                <div className={primaryContentClasses} ref={contentRef}>
                    {isLoggedIn ? (
                        <Navigation
                            ariaLabel={formatter.formattedMessage({ id: 'CoreUI.primaryMenuAriaLabel' })}
                            className={primaryNavClasses}
                        >
                            <Menu
                                ariaLabel={formatter.formattedMessage({ id: 'CoreUI.primaryMenuAriaLabel' })}
                                className={classnames(`${CSS_BLOCK}__menu`)}
                                collapsible
                                id="primary"
                                appHeaderWidth={appHeaderWidth}
                                utilityWidth={utilityWidth}
                            >
                                {homeMenuItem && (
                                    <MenuItem
                                        {...homeMenuItem}
                                        name={formatter.formattedMessage({ id: 'CoreUI.navigateToHome' })}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        anchorClassName={classnames(`${CSS_BLOCK}__anchor--dashboard`)}
                                        isActive={isGov && homeMenuItem.isActive} // home menu item is never shown as active unless Concur Gov
                                        key="dashboard"
                                    >
                                        <Logo
                                            classNameMap={classNameMap}
                                            cssBlock={CSS_BLOCK}
                                            className={homeIconClasses}
                                            isGov={isGov}
                                            isRetiredBrand={isRetiredBrand}
                                        />
                                    </MenuItem>
                                )}
                                {testDriveMenuItem && (
                                    <MenuItem
                                        {...testDriveMenuItem}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        key="testDrive"
                                    />
                                )}
                                {applicationMenuItems && (
                                    applicationMenuItems.map((item, index) => (
                                        <MenuItem
                                            {...item}
                                            classNameMap={classNameMap}
                                            cssBlock={CSS_BLOCK}
                                            hasPopover
                                            key={`application${index}`}
                                        />
                                    ))
                                )}
                                {adminMenuItem && primaryMenuItemCount <= PRIMARY_MENU_ITEM_COUNT_THRESHOLD && (
                                    <MenuItem
                                        {...adminMenuItem}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        hasPopover
                                        key="adminMenuItem"
                                    />
                                )}
                                {linksMenuItem && (primaryMenuItemCount <= PRIMARY_MENU_ITEM_COUNT_THRESHOLD || adminMenuItem) && (
                                    <MenuItem
                                        {...linksMenuItem}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        hasPopover
                                        key="linksMenuItem"
                                    />
                                )}
                                <MenuItem
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    id="overflow"
                                    isOverflow
                                    key="overflowPrimary"
                                />
                            </Menu>
                        </Navigation>
                    ) : (
                        <>
                            <span className={classnames(`${CSS_BLOCK}__nav`)}>
                                <span className={classnames(`${CSS_BLOCK}__brand`)}>
                                    <Logo
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        className={homeIconClasses}
                                        isGov={isGov}
                                        isRetiredBrand={isRetiredBrand}
                                    />
                                </span>
                            </span>
                            {showLanguageDropdown && (
                                <LanguageDropdown
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                />
                            )}
                        </>
                    )}
                    {noMenuDataFallback && React.cloneElement(noMenuDataFallback, {
                        className: classnames(`${CSS_BLOCK}__fallback`),
                        messageClassName: classnames(`${CSS_BLOCK}__fallback-message`),
                    })}
                    {isLoggedIn && (
                        <div className={classnames(`${CSS_BLOCK}__right`)} ref={utilityRef}>
                            {brandingId === CONCUR_THEMES_BRANDING_ID && (
                                <ThemeSwitcher
                                    className={classnames(`${CSS_BLOCK}__theme-switcher`)}
                                    classNameMap={classNameMap}
                                    isGateway
                                />
                            )}
                            {(profileMenuItem || (utilityMenuItems.length > 0)) && (
                                <Navigation
                                    ariaLabel={formatter.formattedMessage({ id: 'CoreUI.utilityMenuAriaLabel' })}
                                    className={utilityNavClasses}
                                >
                                    <Menu
                                        ariaLabel={formatter.formattedMessage({ id: 'CoreUI.utilityMenuAriaLabel' })}
                                        className={classnames(`${CSS_BLOCK}__menu`)}
                                        id="utility"
                                    >
                                        {
                                            isLocalizationUser && (
                                                <>
                                                    <TranslationToolMenuItem
                                                        onLoad={setTranslationToolLoaded}
                                                    />
                                                    {translationToolLoaded && (
                                                        <MenuItem
                                                            cssBlock={CSS_BLOCK}
                                                            classNameMap={classNameMap}
                                                            hasPopover
                                                            isPipe
                                                        />
                                                    )}
                                                </>
                                            )
                                        }
                                        {utilityMenuItems?.map?.((item, index) => (
                                            <React.Fragment key={`fragment${index}`}>
                                                {index !== 0 && (
                                                    <MenuItem
                                                        anchorProps={item?.anchorProps}
                                                        classNameMap={classNameMap}
                                                        cssBlock={CSS_BLOCK}
                                                        key={`pipe${index}`}
                                                        hasPopover
                                                        isPipe
                                                    />
                                                )}
                                                {renderUtilityMenuItem(item, index)}
                                            </React.Fragment>
                                        ))}
                                        {utilityMenuItems?.length > 0 && digitalAssistantLoaded && (
                                            <MenuItem
                                                classNameMap={classNameMap}
                                                cssBlock={CSS_BLOCK}
                                                isPipe
                                            />
                                        )}
                                        <DigitalAssistantMenuItem onLoad={setDigitalAssistantLoaded} />
                                        {utilityMenuItems?.length > 0 && helpPortalLoaded && (
                                            <MenuItem
                                                classNameMap={classNameMap}
                                                cssBlock={CSS_BLOCK}
                                                isPipe
                                            />
                                        )}
                                        <HelpPortalMenuItem
                                            onLoad={setHelpPortalLoaded}
                                            menuData={menuData}
                                        />
                                    </Menu>
                                    {profileMenuItem && (
                                        <ProfileMenuItem
                                            actingAs={actingAs}
                                            classNameMap={classNameMap}
                                            cssBlock={CSS_BLOCK}
                                            isGov={isGov}
                                            profileMenuItem={profileMenuItem}
                                        />
                                    )}
                                </Navigation>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isLoggedIn && pageMenuItems && pageMenuItems.length > 0 && (
                <div className={classnames(classes, `${CSS_BLOCK}--secondary`)}>
                    <div className={secondaryContentClasses}>
                        <Navigation
                            ariaLabel={formatter.formattedMessage({ id: 'CoreUI.secondaryMenuAriaLabel' })}
                            className={secondaryNavClasses}
                            id="cnqr-navbar-secondary"
                        >
                            <Menu
                                ariaLabel={formatter.formattedMessage({ id: 'CoreUI.secondaryMenuAriaLabel' })}
                                id="secondary"
                                appHeaderWidth={appHeaderWidth}
                                className={classnames(`${CSS_BLOCK}__menu`)}
                                collapsible
                            >
                                {pageMenuItems.map((item, index) => (
                                    <MenuItem
                                        {...item}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        hasPopover
                                        key={`secondary${index}`}
                                    />
                                ))}
                                <MenuItem
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    id="overflow"
                                    isOverflow
                                    hasPopover
                                    key="overflowSecondary"
                                />
                            </Menu>
                        </Navigation>
                    </div>
                </div>
            )}
        </>
    );
};

GatewayHeader.displayName = 'GatewayHeader';

GatewayHeader.cssBlock = CSS_BLOCK;

GatewayHeader.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        otherUserName: PropTypes.string,
    }),
    menuData: PropTypes.shape({
        homeMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        applicationMenuItems: PropTypes.arrayOf(
            PropTypes.shape(MenuItem.apiPropTypes),
        ),
        profileMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        adminMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        helpMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        linksMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        testDriveMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        localizationToolMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        askQuestionMenuItem: PropTypes.shape(AskQuestionMenuItem.propTypes),
        subscribeMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        supportMenuItem: PropTypes.shape(MenuItem.apiPropTypes),
        pageMenuItems: PropTypes.arrayOf(
            PropTypes.shape(MenuItem.apiPropTypes),
        ),
        noMenuDataFallback: PropTypes.node,
    }),
    employeeName: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        preferredName: PropTypes.string,
    }),
    // fullName is being replaced by employeeName, but fullName needs to stay
    // for a period of time for backwards compatibility
    fullName: PropTypes.string,
    isGov: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isRetiredBrand: PropTypes.bool,
    logoutUser: PropTypes.func,
    onEndSession: PropTypes.func,
    onStartSession: PropTypes.func,
    showLanguageDropdown: PropTypes.bool,
    stretchContent: PropTypes.bool,
    useFullWidth: PropTypes.bool,
    useSmallViewportBreakpoints: PropTypes.bool,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(GatewayHeader);
