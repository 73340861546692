import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';

const FooterText = (props) => {
    const {
        center,
        className,
        classNameMap,
        cssBlock,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const classes = classnames(
        `${cssBlock}__text`,
        className,
        {
            [`${cssBlock}__text--center`]: center,
        },
    );

    return (
        <div {...otherProps} className={classes} />
    );
};

FooterText.displayName = 'FooterText';

FooterText.propTypes = {
    center: PropTypes.bool,
    className: PropTypes.string,
    classNameMap: PropTypes.object,
    cssBlock: PropTypes.string,
};

export default FooterText;
