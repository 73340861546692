/* eslint-disable react/prop-types */
import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { isFiori } from '@concur/ui-theming';
import {
    GATEWAY_BRANDING_ID,
    SAP_HORIZON_THEMES_BRANDING_ID,
    SAP_QUARTZ_THEMES_BRANDING_ID,
} from '@concur/core-ui-shell/lib/constants';
import PlaceholderComponent from '../PlaceholderComponent/_PlaceholderComponent';

export default (WrappedComponent) => {
    const Component = (props) => {
        const {
            menuData,
            brandingId,
            themeName,
            useFullWidth,
            useSmallViewportBreakpoints,
        } = props;

        const {
            pageMenuItems = [],
        } = menuData || {};

        // sometimes the theme cookie value gets encoded so we need to decode it here
        const decodedThemeName = themeName ? decodeURI(themeName) : undefined;

        let componentType;

        if (!WrappedComponent.cssBlock
                && (menuData || props.actingAs)) {
            componentType = 'header';
        } else if (WrappedComponent.cssBlock === 'sapcnqr-app-footer') {
            componentType = 'footer';
        } else if (WrappedComponent.cssBlock === 'sapcnqr-app-mini-header') {
            componentType = 'mini-header';
        }

        if (componentType) {
            let themeSuffix = 'gateway';
            if (isFiori(brandingId, decodedThemeName)) {
                if (decodedThemeName?.indexOf('_hcb') >= 0) {
                    themeSuffix = 'hcb';
                } else if (decodedThemeName?.indexOf('_hcw') >= 0) {
                    themeSuffix = 'hcw';
                } else if (decodedThemeName?.indexOf('sap_fiori_3') >= 0
                    || brandingId === SAP_QUARTZ_THEMES_BRANDING_ID) {
                    themeSuffix = 'quartz';
                } else if (decodedThemeName?.indexOf('sap_horizon_dark') >= 0) {
                    themeSuffix = 'horizon-dark';
                } else if (decodedThemeName?.indexOf('sap_horizon') >= 0
                    || brandingId === SAP_HORIZON_THEMES_BRANDING_ID) {
                    themeSuffix = 'horizon';
                }
            }
            componentType = `${componentType}-${themeSuffix}`;
        }

        const placeholderComp = () => (
            <PlaceholderComponent
                hasAdditionalContent={pageMenuItems && pageMenuItems.length > 0}
                originalComponentType={componentType}
                useFullWidth={useFullWidth}
                useSmallViewportBreakpoints={useSmallViewportBreakpoints}
            />
        );

        return (
            <WrappedComponent
                {...props}
                cssFilenamePrefix="main"
                placeholderComp={placeholderComp}
            />
        );
    };

    hoistNonReactStatics(Component, WrappedComponent);

    Component.defaultProps = {
        brandingId: GATEWAY_BRANDING_ID,
    };
    Component.propTypes = WrappedComponent.propTypes;
    Component.displayName = WrappedComponent.displayName || WrappedComponent.name || 'withStylesPlaceholder';

    return Component;
};
