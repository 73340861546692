/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chain from 'chain-function';
import classnamesBind from 'classnames/bind';
import { lowerCase } from '@concur/core-ui-shell';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Overlay from '@concur/nui-widgets/lib/Overlay/Overlay';
import AskQuestion from '../AskQuestion/AskQuestion';

const AskQuestionMenuItem = React.forwardRef((props, ref) => {
    const {
        classNameMap,
        cssBlock,
        name,
        id,
        onClose,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const [expanded, setExpanded] = useState(false);

    const handleToggleClick = () => {
        setExpanded(!expanded);
    };

    const handleClose = () => {
        setExpanded(false);
    };

    const drawToggle = () => {
        const anchorClassName = classnames(
            `${cssBlock}__anchor`,
            `${cssBlock}__anchor--tertiary`,
            {
                [`${cssBlock}__anchor--active`]: expanded,
            },
        );

        return (
            <Button
                aria-expanded={expanded}
                aria-haspopup="dialog"
                className={anchorClassName}
                data-test={id ? `menu-${lowerCase(id)}` : null}
                onClick={handleToggleClick}
                size="md"
            >
                {name}
            </Button>
        );
    };

    return (
        <li ref={ref}>
            <Overlay
                disableEdgeDetection
                onHide={handleClose}
                popperPlacement="bottom-end"
                show={expanded}
                targetComponent={drawToggle()}
                targetClassName={classnames(`${cssBlock}__target`)}
            >
                <AskQuestion
                    {...otherProps}
                    onClose={chain(handleClose, onClose)}
                />
            </Overlay>
        </li>
    );
});

AskQuestionMenuItem.propTypes = {
    classNameMap: PropTypes.object,
    cssBlock: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    ...AskQuestion.propTypes,
};

AskQuestionMenuItem.defaultProps = {
    ...AskQuestion.defaultProps,
};

export default AskQuestionMenuItem;
