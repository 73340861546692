/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import NavMenu from '@concur/nui-widgets/lib/NavMenu/NavMenu';
import useAutoOverflow from '@concur/nui-widgets/lib/utils/useAutoOverflow';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import * as styles from './ShellbarNavBar-*.css';

const CSS_BLOCK = 'sapcnqr-shellbar-nav-bar';

const ShellbarNavBar = (props) => {
    const {
        className,
        classNameMap,
        menuItems,
        formatter,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const navBarRef = useRef();

    const classes = classnames(
        className,
        CSS_BLOCK,
    );

    const listClasses = classnames(
        `${CSS_BLOCK}__list`,
    );

    const listItemClasses = classnames(
        `${CSS_BLOCK}__list-item`,
    );

    const subItemClasses = classnames(
        `${CSS_BLOCK}__subitem`,
    );

    const itemIconClasses = classnames(
        `${CSS_BLOCK}__item-icon`,
    );

    const itemTextClasses = classnames(
        `${CSS_BLOCK}__item-text`,
    );

    const overflowClasses = classnames(
        `${CSS_BLOCK}__overflow`,
    );

    const getItemClasses = (item) => classnames(
        `${CSS_BLOCK}__item`,
        {
            [`${CSS_BLOCK}__item--active`]: item?.isActive,
        },
    );

    const renderSubMenuItems = (items, level) => (
        items.map((subItem, index) => {
            if (subItem.subItems) {
                return (
                    <NavMenu.Item
                        label={subItem.name}
                        buttonProps={{
                            className: subItemClasses,
                            innerTextClasses: itemTextClasses,
                        }}
                        linkProps={{
                            className: subItemClasses,
                            innerTextClasses: itemTextClasses,
                        }}
                        key={`navitem_${level}_${index}`}
                    >
                        {renderSubMenuItems(subItem.subItems, level + 1)}
                    </NavMenu.Item>
                );
            }

            return (
                <NavMenu.Item
                    label={subItem.name}
                    linkProps={{
                        className: subItemClasses,
                        innerTextClasses: itemTextClasses,
                    }}
                    key={`navitem_${level}_${index}`}
                    href={subItem.url}
                />
            );
        }));

    const getNavChildren = () => menuItems.map((item, index) => {
        if (item.subItems) {
            return (
                <NavMenu
                    buttonContents={item.name}
                    buttonProps={{
                        className: getItemClasses(item),
                        innerTextClasses: itemTextClasses,
                    }}
                    className={listItemClasses}
                    iconProps={{
                        className: itemIconClasses,
                    }}
                    key={`navitem_1_${index}`}
                    menuAriaLabel={item.name}
                >
                    {renderSubMenuItems(item.subItems, 2)}
                </NavMenu>
            );
        }

        return (
            <NavMenu.Item
                label={item.name}
                className={listItemClasses}
                data-no-overflow={item.isActive ? true : undefined}
                key={`navitem_1_${index}`}
                linkProps={{
                    className: getItemClasses(item),
                    innerTextClasses: itemTextClasses,
                }}
                href={item.url}
            />
        );
    });

    const navChildren = getNavChildren();
    const {
        isOverflowing,
        childrenToRender,
        overflowChildrenToRender,
    } = useAutoOverflow({
        targetRef: navBarRef,
        children: navChildren,
        overflowPadding: 150,
    });

    return (
        <NavMenu.Bar
            ariaLabel={formatter.formattedMessage({ id: 'CoreUI.secondaryMenuAriaLabel' })}
            className={classes}
            listProps={{
                className: listClasses,
            }}
            ref={navBarRef}
        >
            {childrenToRender}
            {isOverflowing && (
                <NavMenu
                    buttonContents={formatter?.formattedMessage({ id: 'CoreUIComponents.more' })}
                    buttonProps={{
                        className: overflowClasses,
                        innerTextClasses: itemTextClasses,
                    }}
                    className={listItemClasses}
                    iconProps={{
                        className: itemIconClasses,
                    }}
                    menuAriaLabel={formatter?.formattedMessage({ id: 'CoreUI.moreLinksLabel' })}
                >
                    {React.Children.map(
                        overflowChildrenToRender,
                        (child) => {
                            // need to switch `NavMenu` components to `NavMenu.Item`
                            // components when they move to the overflow menu
                            if (child?.type?.displayName === 'NavMenu') {
                                return (
                                    <NavMenu.Item
                                        label={child?.props?.buttonContents}
                                        buttonProps={{
                                            className: subItemClasses,
                                            innerTextClasses: itemTextClasses,
                                        }}
                                        linkProps={{
                                            className: subItemClasses,
                                            innerTextClasses: itemTextClasses,
                                        }}
                                    >
                                        {child?.props?.children}
                                    </NavMenu.Item>
                                );
                            }

                            return React.cloneElement(child, {
                                linkProps: {
                                    className: subItemClasses,
                                    innerTextClasses: itemTextClasses,
                                },
                            });
                        },
                    )}
                </NavMenu>
            )}
        </NavMenu.Bar>
    );
};

ShellbarNavBar.displayName = 'ShellbarNavBar';

ShellbarNavBar.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
)(ShellbarNavBar);
