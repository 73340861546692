import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { addScriptToDOM } from '@concur/core-ui-shell';

const CookiePreferences = (props) => {
    useEffect(() => {
        if (!props.demo) {
            // eslint-disable-next-line max-len
            const scriptUrl = `//consent.trustarc.com/notice?domain=concursolutions.com&c=teconsent&language=${props.lang}&js=nj&noticeType=bb&text=true${props.country ? `&country=${props.country}` : ''}`;

            const attributes = {
                type: 'text/javascript',
                async: true,
                crossorigin: 'anonymous',
            };

            addScriptToDOM(scriptUrl, attributes, 'sapcnqr-trustarc');
        }
    }, []);

    let children;
    const charCodeZWS = 8203;

    if (props.demo) {
        // NOT using Link component to simulate unstyled <a> tag coming from TrustArc
        children = (<a href="#teconsent">Cookies Preferences (Demo)</a>);
    }

    return (
        <div id="teconsent">
            {children}
            {String.fromCharCode(charCodeZWS)}
        </div>
    );
};

CookiePreferences.displayName = 'CookiePreferences';

CookiePreferences.propTypes = {
    country: PropTypes.string,
    demo: PropTypes.bool,
    lang: PropTypes.string,
};

export default CookiePreferences;
