import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Checkbox from '@concur/nui-widgets/lib/Forms/Checkbox';
import Fieldset from '@concur/nui-widgets/lib/Forms/Fieldset';
import Radio from '@concur/nui-widgets/lib/Forms/Radio';
import RadioGroup from '@concur/nui-widgets/lib/Forms/RadioGroup';
import Switch from '@concur/nui-widgets/lib/Switch/Switch';
import Text from '@concur/nui-widgets/lib/Text/Text';
import { withThemeStyles } from '@concur/react-ui-theming';
import classnamesBind from 'classnames/bind';
import { withFormatter } from '@concur/nui-intl-runtime';
import { withErrorBoundary, compose } from '@concur/core-ui-shell';
import {
    GATEWAY_BRANDING_ID,
    SAP_HORIZON_THEMES_BRANDING_ID,
} from '@concur/core-ui-shell/lib/constants';

import { THEME_SELECTOR_FORM } from '../constants';
import {
    saveToSession,
    saveThemeCookie,
    removeThemeCookie,
    getShowPreviewSwitch,
    getIsThemingLive,
    getBaseThemeVariant,
    getBaseThemeVariants,
    getFullThemeName,
    isHighContrastTheme,
} from '../utils/themeUtils';
import * as styles from './AppThemeSelector-*.css';

const CSS_BLOCK = 'sapcnqr-app-theme-selector';

const ThemeSelector = ({
    brandingId,
    classNameMap,
    demo,
    formatter,
    inThemePreviewMode,
    onThemePreviewModeChange,
    themeName,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const showPreviewSwitch = getShowPreviewSwitch(brandingId, inThemePreviewMode);
    const isThemingLive = getIsThemingLive(brandingId, inThemePreviewMode);
    const baseThemeVariants = getBaseThemeVariants(brandingId, inThemePreviewMode);

    const isHighContrast = isHighContrastTheme(themeName);

    const [previewMode, setPreviewMode] = useState(inThemePreviewMode);
    const [themeVariant, setThemeVariant] = useState(getBaseThemeVariant(themeName));
    const [highContrast, setHighContrast] = useState(isHighContrast);

    // bringing in nui-layouts seemed like a heavy lift just to
    // get a couple CSS classnames so they are just hard-coded
    const classes = classnames(
        'col-lg-6',
        'col-md-8',
        'col-sm-10',
        'col-xs-12',
    );

    const applyTheme = () => {
        if (brandingId === GATEWAY_BRANDING_ID) {
            if (previewMode) {
                // save branding id and theme preview to session
                if (demo) {
                    onThemePreviewModeChange(
                        SAP_HORIZON_THEMES_BRANDING_ID,
                        getFullThemeName(themeVariant, highContrast),
                        true,
                    );
                } else {
                    saveThemeCookie(getFullThemeName(themeVariant, highContrast));
                    saveToSession(SAP_HORIZON_THEMES_BRANDING_ID, true);
                }
            } else {
                window.location.reload();
            }
        } else if (brandingId === SAP_HORIZON_THEMES_BRANDING_ID
            && inThemePreviewMode && !previewMode) {
            // save old branding id and remove theme preview from session
            if (demo) {
                onThemePreviewModeChange(
                    GATEWAY_BRANDING_ID,
                    null,
                    false,
                );
            } else {
                removeThemeCookie();
                saveToSession(GATEWAY_BRANDING_ID, false, demo);
            }
        } else {
            saveThemeCookie(getFullThemeName(themeVariant, highContrast));
            window.location.reload();
        }
    };

    useEffect(() => {
        setPreviewMode(inThemePreviewMode);
        setThemeVariant(getBaseThemeVariant(themeName));
        setHighContrast(isHighContrastTheme(themeName));
    }, [brandingId, themeName]);

    return (
        <div className="row">
            <div className={classes}>
                <Text className={classnames(`${CSS_BLOCK}__text`)}>
                    {formatter.formattedMessage({ id: 'CoreUI.themeSelector.appearanceSubtitle' })}
                </Text>
                {showPreviewSwitch && (
                    <Switch
                        checked={previewMode}
                        className={classnames(`${CSS_BLOCK}__switch`)}
                        label={formatter.formattedMessage({ id: 'CoreUI.themeSelector.enablePreview' })}
                        onChange={(e, checked) => setPreviewMode(checked)}
                    />
                )}
                {(isThemingLive || previewMode) && (
                    <>
                        <RadioGroup
                            label={formatter.formattedMessage({ id: 'CoreUI.themeSelector.themeVariant' })}
                            name="themeVariant"
                            onChange={(e) => {
                                setThemeVariant(e.target.value);
                                if (e.target.value === 'concur_gateway') {
                                    setHighContrast(false);
                                }
                            }}
                        >
                            {baseThemeVariants.map((variant, index) => (
                                <Radio
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`themeVariantRadio${index}`}
                                    checked={themeVariant === variant}
                                    value={variant}
                                >
                                    {formatter.formattedMessage({ id: `CoreUI.themeSelector.themeVariant_${variant}` })}
                                </Radio>
                            ))}
                        </RadioGroup>
                        {themeVariant !== 'concur_gateway' && (
                            <Fieldset
                                className={classnames(`${CSS_BLOCK}__fieldset`)}
                                legend={formatter.formattedMessage({ id: 'CoreUI.themeSelector.accessibility' })}
                            >
                                <Checkbox
                                    checked={highContrast}
                                    label={formatter.formattedMessage({ id: 'CoreUI.themeSelector.highContrast' })}
                                    onChange={setHighContrast}
                                />
                            </Fieldset>
                        )}
                        {showPreviewSwitch && (
                            <Text className={classnames(`${CSS_BLOCK}__text`, `${CSS_BLOCK}__text--session`)}>
                                {formatter.formattedMessage({ id: 'CoreUI.themeSelector.sessionOnly' })}
                            </Text>
                        )}
                    </>
                )}
                <Button onClick={applyTheme}>
                    {formatter.formattedMessage({ id: 'CoreUI.themeSelector.applyTheme' })}
                </Button>
                {/* the ASP page container already creates a form for us to use */}
                <input
                    type="hidden"
                    id={THEME_SELECTOR_FORM.BRANDING_ID_INPUT_ID}
                    name={THEME_SELECTOR_FORM.BRANDING_ID_INPUT_NAME}
                    value=""
                />
                <input
                    type="hidden"
                    id={THEME_SELECTOR_FORM.THEME_PREVIEW_INPUT_ID}
                    name={THEME_SELECTOR_FORM.THEME_PREVIEW_INPUT_NAME}
                    value=""
                />
            </div>
        </div>
    );
};

ThemeSelector.displayName = 'ThemeSelector';

ThemeSelector.cssBlock = CSS_BLOCK;

ThemeSelector.propTypes = {
    demo: PropTypes.bool,
    inThemePreviewMode: PropTypes.bool,
    onThemePreviewModeChange: PropTypes.func,
};

ThemeSelector.defaultProps = {
    onThemePreviewModeChange: () => {},
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(ThemeSelector);
