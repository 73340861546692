import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Link from '@concur/nui-widgets/lib/Link/Link';
import Toolbar from '@concur/nui-widgets/lib/Toolbar/Toolbar';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { withErrorBoundary, compose } from '@concur/core-ui-shell';
import ProfileIdentifier from './_ProfileIdentifier';
import ActingAs from './_ActingAs';
import ActingAsFiori from './_ActingAsFiori';
import * as styles from './UserProfileMenu-*.css';

const CSS_BLOCK = 'sapcnqr-user-profile-menu';

const UserProfileMenu = ({
    actingAs,
    classNameMap,
    formatter,
    isGov,
    onClose,
    onEndLoading,
    onStartLoading,
    profileUrl,
    actingAsQuickHelpRef,
    actingAsUserDropdownRef,
}) => {
    const classnames = classnamesBind.bind(classNameMap);

    const { allUsers, companyName, otherUserName } = actingAs;
    const isActingForOthers = !!(allUsers || companyName || otherUserName);

    let actingAsComp;
    if (isGov) {
        actingAsComp = (
            <ActingAs
                isActingForOthers={isActingForOthers}
                isGov={isGov}
                onClose={onClose}
                onEndLoading={onEndLoading}
                onStartLoading={onStartLoading}
                options={actingAs.options}
                quickHelpRef={actingAsQuickHelpRef}
                userDropdownRef={actingAsUserDropdownRef}
            />
        );
    } else {
        actingAsComp = (
            <div className={classnames(`${CSS_BLOCK}__acting-as`)}>
                <ActingAsFiori
                    isActingForOthers={isActingForOthers}
                    isGov={isGov}
                    onClose={onClose}
                    onEndLoading={onEndLoading}
                    onStartLoading={onStartLoading}
                    options={actingAs.options}
                    quickHelpRef={actingAsQuickHelpRef}
                    userDropdownRef={actingAsUserDropdownRef}
                />
            </div>
        );
    }

    return (
        <div
            className={classnames(CSS_BLOCK)}
            data-test="user-profile-menu"
        >
            <ProfileIdentifier
                actingAs={actingAs}
                classNameMap={classNameMap}
                cssBlock={CSS_BLOCK}
            />

            <div className={classnames(`${CSS_BLOCK}__toolbar-wrapper`)}>
                <Toolbar
                    toolbarStyle="transparent"
                >
                    <Toolbar.Center>
                        <Link
                            className={classnames(`${CSS_BLOCK}__link`)}
                            data-test="user-profile-menu-profile-link"
                            disabled={profileUrl === '#'}
                            href={profileUrl !== '#' ? profileUrl : undefined}
                            matchButtonHeight
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.profile.profileSettings' })}
                        </Link>
                        <span className={classnames(`${CSS_BLOCK}__pipe`)} />
                        <Button
                            data-test="user-profile-menu-signout-link"
                            onClick={() => { window.location.href = '/errors/loggedout_gvt.asp'; }}
                            type="link"
                        >
                            {formatter.formattedMessage({ id: 'CoreUI.signOut' })}
                        </Button>
                    </Toolbar.Center>
                </Toolbar>
            </div>

            {actingAsComp}
        </div>
    );
};

UserProfileMenu.displayName = 'UserProfileMenu';

UserProfileMenu.cssBlock = CSS_BLOCK;

UserProfileMenu.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        otherUserName: PropTypes.string,
    }),
    isGov: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onEndLoading: PropTypes.func,
    onStartLoading: PropTypes.func,
    profileUrl: PropTypes.string,
    actingAsQuickHelpRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    actingAsUserDropdownRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

UserProfileMenu.defaultProps = {
    actingAs: {},
    profileUrl: '#',
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
    withErrorBoundary,
)(UserProfileMenu);
