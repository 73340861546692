import React from 'react';
import { compose, withStylesLoaded } from '@concur/core-ui-shell';
import ThemeSelector from './_ThemeSelector';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';
import withNuiIntl from '../utils/withNuiIntl';
import withStylesPlaceholder from '../utils/withStylesPlaceholder';

// this wrapper component is necessary to wrap the component
// in a ThemeProvider (handled in withStylesLoaded)
const AppThemeSelector = (props) => {
    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={ThemeSelector.cssBlock}
            correlationId={errorId}
        />
    );

    return (
        <ThemeSelector
            {...props}
            errorComp={fallbackComp}
        />
    );
};

AppThemeSelector.displayName = 'AppThemeSelector';

AppThemeSelector.propTypes = {
    ...ThemeSelector.propTypes,
};

export default compose(
    withStylesPlaceholder,
    withStylesLoaded,
    withNuiIntl,
)(AppThemeSelector);
