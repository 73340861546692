/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import shortId from '@concur/nui-widgets/lib/utils/shortId';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import Menu from './_Menu';
import MenuItem from './_MenuItem';
import * as styles from './ProductMenu-*.css';

const CSS_BLOCK = 'sapcnqr-product-menu';

const ProductMenu = (props) => {
    const {
        classNameMap,
        formatter,
        menuData,
        isFioriProductMenu,
    } = props;

    const {
        homeMenuItem,
        applicationMenuItems,
        adminMenuItem,
        linksMenuItem,
        testDriveMenuItem,
        subscribeMenuItem,
        noMenuDataFallback,
    } = menuData;

    const classnames = classnamesBind.bind(classNameMap);

    if (noMenuDataFallback) {
        return (
            <div className={classnames(CSS_BLOCK)}>
                {noMenuDataFallback}
            </div>
        );
    }

    const applicationsLabelId = shortId.generate();
    const linksLabelId = shortId.generate();
    const adminLabelId = shortId.generate();

    return (
        <div className={classnames(CSS_BLOCK)}>
            <div className={classnames(`${CSS_BLOCK}__column`)}>
                <Menu
                    className={classnames(`${CSS_BLOCK}__menu`)}
                    parentMenuCssBlock={CSS_BLOCK}
                    isFioriProductMenu={isFioriProductMenu}
                >
                    {homeMenuItem && (
                        <>
                            <MenuItem
                                {...homeMenuItem}
                                classNameMap={classNameMap}
                                cssBlock={CSS_BLOCK}
                                isSubItem
                            />
                            <MenuItem
                                classNameMap={classNameMap}
                                cssBlock={CSS_BLOCK}
                                isDivider
                            />
                        </>
                    )}
                    {testDriveMenuItem && (
                        <MenuItem
                            {...testDriveMenuItem}
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            isSubItem
                        />
                    )}
                    {subscribeMenuItem && (
                        <MenuItem
                            {...subscribeMenuItem}
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            isSubItem
                        />
                    )}
                    {applicationMenuItems && (
                        <>
                            {(testDriveMenuItem || subscribeMenuItem) && (
                                <MenuItem
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    isDivider
                                />
                            )}
                            <Menu
                                className={classnames(`${CSS_BLOCK}__menu`)}
                                parentMenuCssBlock={CSS_BLOCK}
                                isFioriProductMenu={isFioriProductMenu}
                                role="group"
                                aria-labelledby={applicationsLabelId}
                            >
                                <MenuItem
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    id={applicationsLabelId}
                                    isHeading
                                >
                                    <Icon
                                        ariaHidden
                                        className={classnames(`${CSS_BLOCK}__icon`)}
                                        iconName="window-restore"
                                        size="sm-plus"
                                    />
                                    {formatter.formattedMessage({ id: 'CoreUI.applications' })}
                                </MenuItem>
                                {applicationMenuItems.map((item, index) => (
                                    <MenuItem
                                        {...item}
                                        headingId={index === 0 ? applicationsLabelId : null}
                                        classNameMap={classNameMap}
                                        cssBlock={CSS_BLOCK}
                                        isSubItem
                                        key={`application${index}`}
                                    />
                                ))}
                            </Menu>
                        </>
                    )}
                </Menu>
            </div>
            {linksMenuItem && linksMenuItem.subItems && (
                <div className={classnames(`${CSS_BLOCK}__column`)}>
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`)}
                        parentMenuCssBlock={CSS_BLOCK}
                        isFioriProductMenu={isFioriProductMenu}
                        role="group"
                        aria-labelledby={linksLabelId}
                    >
                        <>
                            <MenuItem
                                classNameMap={classNameMap}
                                cssBlock={CSS_BLOCK}
                                id={linksLabelId}
                                isHeading
                            >
                                <Icon
                                    ariaHidden
                                    className={classnames(`${CSS_BLOCK}__icon`)}
                                    iconName="combined-expense"
                                    size="sm-plus"
                                />
                                {linksMenuItem.name}
                            </MenuItem>
                            {linksMenuItem.subItems.map((item, index) => (
                                <MenuItem
                                    {...item}
                                    classNameMap={classNameMap}
                                    cssBlock={CSS_BLOCK}
                                    isSubItem
                                    key={`links${index}`}
                                    headingId={index === 0 ? linksLabelId : null}
                                />
                            ))}
                        </>
                    </Menu>
                </div>
            )}
            {adminMenuItem && adminMenuItem.subItems && (
                <div className={classnames(`${CSS_BLOCK}__column`)}>
                    <Menu
                        className={classnames(`${CSS_BLOCK}__menu`)}
                        parentMenuCssBlock={CSS_BLOCK}
                        isFioriProductMenu={isFioriProductMenu}
                        role="group"
                        aria-labelledby={adminLabelId}
                    >
                        <MenuItem
                            classNameMap={classNameMap}
                            cssBlock={CSS_BLOCK}
                            id={adminLabelId}
                            isHeading
                        >
                            <Icon
                                ariaHidden
                                className={classnames(`${CSS_BLOCK}__icon`)}
                                iconName="gear"
                                size="sm-plus"
                            />
                            {adminMenuItem.name}
                        </MenuItem>
                        {adminMenuItem.subItems.map((item, index) => (
                            <MenuItem
                                {...item}
                                classNameMap={classNameMap}
                                cssBlock={CSS_BLOCK}
                                isSubItem
                                key={`admin${index}`}
                                headingId={index === 0 ? adminLabelId : null}
                            />
                        ))}
                    </Menu>
                </div>
            )}
        </div>
    );
};

ProductMenu.displayName = 'ProductMenu';

ProductMenu.propTypes = {
    className: PropTypes.string,
    isFioriProductMenu: PropTypes.bool,
    menuData: PropTypes.object,
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
)(ProductMenu);
