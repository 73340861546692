import classnamesBind from 'classnames/bind';
import React, { useRef } from 'react';
import { withThemeStyles } from '@concur/react-ui-theming';
import { randomArrayValue } from '@concur/core-ui-shell';
import * as styles from './ErrorIllustration-*.css';

const CSS_BLOCK = 'sapcnqr-error-illustration';

const ErrorIllustration = ({ className, classNameMap }) => {
    const classnames = classnamesBind.bind(classNameMap);

    const skinTones = [
        '#8d5524',
        '#c68642',
        '#e0ac69',
        '#f1c27d',
        '#ffdbac',
        '#f9c9a5',
        '#38220E',
        '#c58c85',
        '#ecbcb4',
        '#d1a3a4',
        '#a1665e',
        '#503335',
        '#592f2a',
    ];
    const randomSkinTone = randomArrayValue(skinTones);
    // prevents updating skin color through the lifecycle
    const colorRef = useRef(randomSkinTone);

    const classes = classnames(CSS_BLOCK, className);
    const holeClasses = classnames(`${CSS_BLOCK}__hole`);
    const holeShadowClasses = classnames(`${CSS_BLOCK}__shadow`);
    const sleeveClasses = classnames(`${CSS_BLOCK}__sleeve`);
    const buttonClasses = classnames(`${CSS_BLOCK}__button`);
    const signpostClasses = classnames(`${CSS_BLOCK}__signpost`);

    return (
        <div className={classes}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 199 162" viewBox="0 0 199 162" preserveAspectRatio="xMinYMid">
                <defs />
                <ellipse cx="43" cy="88" className={holeClasses} rx="43" ry="74" />
                <path className={holeShadowClasses} d="M43,14c17.4,0,31.5,33.1,31.5,74S60.4,162,43,162c23.7,0,43-33.1,43-74S66.7,14,43,14z" />
                <path className={sleeveClasses} d="M0.4,78.5C0.1,81.6,0,84.8,0,88c0,17.6,3.6,33.8,9.6,46.5H131v-56H0.4z" />
                <circle cx="120" cy="125" r="4.5" className={buttonClasses} />
                <path className={signpostClasses} d="M165 0H193V152H165z" />
                <path fill={colorRef.current} d="M131,80h5.3c1.1,0,2.2-0.4,3-1.2c0,0,6.7-6.6,9.5-9.2C155.5,63.5,165,56,165,56v17h29c2.2,0,4,3.1,4,7 s-1.8,7-4,7h-13v3h14c2.2,0,4,3.1,4,7s-1.8,7-4,7h-13v3h13c2.2,0,4,3.1,4,7s-1.8,7-4,7h-14v3h13c2.2,0,4,3.1,4,7c0,3.9-1.8,7-4,7 h-35.8c-2.1,0-4.3-0.3-6.3-1l-12.6-4.2c-1.5-0.5-3.1-0.8-4.7-0.8H131V80z" />
            </svg>
        </div>
    );
};

export default withThemeStyles(styles)(ErrorIllustration);
