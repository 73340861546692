import React from 'react';
import AppContainerMenuItem from './_AppContainerMenuItem';

const DigitalAssistantMenuItem = ({
    onLoad,
}) => (
    <AppContainerMenuItem
        fireOnContainerLoaded="ui-floorplans-loaded"
        listenForEmbeddedAppLoaded="digital-assistant-loaded"
        onLoad={onLoad}
        fireOnContainerReady="digital-assistant-menu-container-added"
        containerId="digital-assistant-menu"
    />
);

DigitalAssistantMenuItem.displayName = 'DigitalAssistantMenuItem';

export default DigitalAssistantMenuItem;
