import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import Text from '@concur/nui-widgets/lib/Text/Text';
import withThemeStyles from '@concur/react-ui-theming/lib/utils/withThemeStyles';
import classNamesBind from 'classnames/bind';
import React from 'react';
import ScreenReaderMessageQueue from '../utils/ScreenReaderMessageQueue';
import * as styles from './SiteBanner-*.css';

const CSS_BLOCK = 'sapcnqr-site-banner';

const SiteBanner = React.forwardRef(({
    accentColor,
    classNameMap,
    iconName,
    sticky,
    stickyFrame,
    text,
    ...props
}, ref) => {
    const classnames = classNamesBind.bind(classNameMap);
    return (
        <>
            {stickyFrame
            && (
                <div
                    className={classnames(
                        `${CSS_BLOCK}__sticky-frame`,
                        `${CSS_BLOCK}__sticky-frame--accent-color-${accentColor}`,
                    )}
                />
            )}
            <div
                {...props}
                className={classnames(
                    CSS_BLOCK,
                    `${CSS_BLOCK}--accent-color-${accentColor}`,
                    {
                        [`${CSS_BLOCK}--sticky`]: sticky,
                    },
                )}
                ref={ref}
            >
                <Icon
                    ariaHidden
                    className={classnames(
                        `${CSS_BLOCK}__icon`,
                    )}
                    iconName={iconName}
                    size="sm"
                />
                <Text
                    className={classnames(
                        CSS_BLOCK,
                        `${CSS_BLOCK}__text`,
                        `${CSS_BLOCK}__text--accent-color-${accentColor}`,
                    )}
                    maxLines={1}
                >
                    {text}
                </Text>
                <ScreenReaderMessageQueue announcement={text} />
            </div>
        </>
    );
});

export default withThemeStyles(styles)(SiteBanner);
