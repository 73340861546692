import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import Overlay from '@concur/nui-widgets/lib/Overlay/Overlay';
import Spinner from '@concur/nui-widgets/lib/Spinner/Spinner';
import { withFormatter } from '@concur/nui-intl-runtime';
import { lowerCase } from '@concur/core-ui-shell';
import AppHeaderContext from './_AppHeaderContext';
import UserProfileMenu from '../UserProfileMenu/UserProfileMenu';
import { getProfileDisplayText } from '../UserProfileMenu/_ProfileIdentifier';
import FallbackComponent from '../FallbackComponent/_FallbackComponent';

const ProfileMenuItem = (props) => {
    const {
        actingAs,
        classNameMap,
        cssBlock,
        formatter,
        isGov,
        profileMenuItem,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const {
        employeeDisplayName,
    } = useContext(AppHeaderContext) || {};

    const [expanded, setExpanded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleStartLoading = () => {
        setShowSpinner(true);
    };

    const handleEndLoading = () => {
        setShowSpinner(false);
    };

    const handleToggleClick = () => {
        setExpanded(!expanded);
    };

    const handleClose = () => {
        setExpanded(false);
    };

    const drawToggle = () => {
        const { allUsers, companyName, otherUserName } = actingAs;
        const isActingForOthers = !!(allUsers || companyName || otherUserName);

        const buttonClassName = classnames(
            `${cssBlock}__anchor`,
            `${cssBlock}__profile`,
            {
                [`${cssBlock}__anchor--active`]: expanded,
                [`${cssBlock}__profile--acting-as`]: isActingForOthers,
            },
        );

        const buttonLabel = expanded
            ? formatter.formattedMessage({ id: 'CoreUI.profile.closeProfileMenu' })
            : formatter.formattedMessage({ id: 'CoreUI.profile.openProfileMenu' });

        const profileDisplayText = getProfileDisplayText({
            actingAs,
            formatter,
            employeeDisplayName,
            shortNames: true,
        });

        return (
            <button
                aria-expanded={expanded}
                aria-haspopup="dialog"
                className={buttonClassName}
                data-test={profileMenuItem.id ? `menu-${lowerCase(profileMenuItem.id)}` : null}
                onClick={handleToggleClick}
                type="button"
            >
                {isActingForOthers ? (
                    <div className={classnames(`${cssBlock}__profile-label`)}>
                        <div className={classnames(`${cssBlock}__profile-description`)} title={profileDisplayText}>
                            {profileDisplayText}
                        </div>
                        <span className={classnames(`${cssBlock}__screen-reader-only`)}>
                            {buttonLabel}
                        </span>
                    </div>
                ) : (
                    <>
                        <div
                            aria-hidden
                            className={classnames(`${cssBlock}__profile-label`)}
                        >
                            {profileMenuItem.name}
                        </div>
                        <span className={classnames(`${cssBlock}__screen-reader-only`)}>
                            {buttonLabel}
                        </span>
                    </>
                )}
                <div className={classnames(`${cssBlock}__profile-label`)}>
                    <Icon ariaHidden className={classnames(`${cssBlock}__icon`, `${cssBlock}__icon--squash`)} iconName="arrow-1-s" />
                </div>
                <Icon
                    ariaHidden
                    className={classnames(`${cssBlock}__icon`)}
                    data-test="user-icon-profile"
                    iconName={isActingForOthers ? 'user-admin' : 'user'}
                    size="lg"
                />
            </button>
        );
    };

    const profileQuickHelpRef = useRef();
    const profileUserDropdownRef = useRef();

    const fallbackComp = (errorId) => (
        <FallbackComponent
            componentCssBlock={UserProfileMenu.cssBlock}
            correlationId={errorId}
        />
    );

    return (
        <>
            <Overlay
                disableEdgeDetection
                onClickOutside={(event) => {
                    if (profileQuickHelpRef?.current?.contains?.(event?.target)
                        || profileUserDropdownRef?.current?.contains?.(event?.target)) {
                        return true;
                    }

                    return false;
                }}
                onHide={handleClose}
                popperPlacement="bottom-end"
                show={expanded}
                targetComponent={drawToggle()}
                targetClassName={classnames(`${cssBlock}__target`)}
            >
                <UserProfileMenu
                    actingAs={actingAs}
                    isGov={isGov}
                    onClose={handleClose}
                    onEndLoading={handleEndLoading}
                    onStartLoading={handleStartLoading}
                    profileUrl={profileMenuItem.url}
                    errorComp={fallbackComp}
                    actingAsQuickHelpRef={profileQuickHelpRef}
                    actingAsUserDropdownRef={profileUserDropdownRef}
                />
            </Overlay>
            <Spinner
                message={formatter.formattedMessage({ id: 'CoreUI.switchingActingAsView' })}
                type="fullScreen"
                visible={showSpinner}
            />
        </>
    );
};

ProfileMenuItem.displayName = 'ProfileMenuItem';

ProfileMenuItem.propTypes = {
    actingAs: PropTypes.shape({
        allUsers: PropTypes.bool,
        companyName: PropTypes.string,
        otherUserName: PropTypes.string,
    }),
    cssBlock: PropTypes.string,
    isGov: PropTypes.bool,
    profileMenuItem: PropTypes.object,
};

ProfileMenuItem.defaultProps = {
    actingAs: {},
    profileMenuItem: {},
};

export default withFormatter(ProfileMenuItem);
