import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import shortId from '@concur/nui-widgets/lib/utils/shortId';

const GovLogo = (props) => {
    const {
        classNameMap,
        fill1ClassName,
        fill2ClassName,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const idSuffix = shortId.generate();

    return (
        <svg
            id={`ConcurGov_logo_${idSuffix}`}
            data-test="ConcurGov_logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 394.38 57.6"
            role="img"
            {...otherProps}
        >
            <title>Concur Gov</title>
            <path
                className={classnames(fill1ClassName)}
                d="M89,113.83c0-10.24,7.74-17.23,17.73-17.23a15.24,15.24,0,0,1,13.94,8.14l-6.09,3a9,9,0,0,0-7.85-4.85c-6,0-10.44,4.65-10.44,10.94s4.4,10.94,10.44,10.94a9,9,0,0,0,7.85-4.84l6.09,2.94c-2.4,4.15-6.59,8.2-13.94,8.2C96.73,131.07,89,124.07,89,113.83Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M123.31,113.83c0-10,7.34-17.23,17.38-17.23s17.44,7.19,17.44,17.23-7.34,17.24-17.44,17.24S123.31,123.87,123.31,113.83Zm27.53,0c0-6.24-4-10.94-10.15-10.94s-10.09,4.7-10.09,10.94,4,10.94,10.09,10.94S150.84,120,150.84,113.83Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M187.11,130.47l-15.89-21.73v21.73h-7.09V97.15h7.29l15.44,20.93V97.15H194v33.32Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M200,113.83c0-10.24,7.74-17.23,17.73-17.23a15.24,15.24,0,0,1,13.94,8.14l-6.09,3a9,9,0,0,0-7.85-4.85c-6,0-10.44,4.65-10.44,10.94s4.4,10.94,10.44,10.94a9,9,0,0,0,7.85-4.84l6.09,2.94c-2.4,4.15-6.59,8.2-13.94,8.2C207.69,131.07,200,124.07,200,113.83Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M236.87,117.18v-20h7.19v19.78c0,4.65,2.7,7.84,7.84,7.84s7.8-3.19,7.8-7.84V97.15h7.24v20c0,8.29-4.89,13.94-15,13.94S236.87,125.37,236.87,117.18Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M292.32,130.47l-6.54-11.84h-5.2v11.84h-7.09V97.15h15.58c6.95,0,11.19,4.54,11.19,10.74,0,5.89-3.69,9-7.29,9.89l7.49,12.69Zm.7-22.58c0-2.8-2.15-4.5-5-4.5h-7.45v9H288C290.87,112.38,293,110.68,293,107.89Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M305,113.83c0-10.54,8-17.23,17.74-17.23A15.55,15.55,0,0,1,336.53,104l-5.89,3.2a9.44,9.44,0,0,0-7.89-4.35c-6.05,0-10.45,4.65-10.45,10.94s4.4,10.94,10.45,10.94a11.24,11.24,0,0,0,7.09-2.55v-4h-8.79V112h15.88v12.79a18.47,18.47,0,0,1-14.18,6.3C313,131.12,305,124.32,305,113.83Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M342,113.83c0-10,7.34-17.23,17.39-17.23s17.43,7.19,17.43,17.23-7.34,17.24-17.43,17.24S342,123.87,342,113.83Zm27.53,0c0-6.24-4-10.94-10.14-10.94s-10.1,4.7-10.1,10.94,4,10.94,10.1,10.94S369.51,120,369.51,113.83Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M390.14,130.47,377.25,97.15h8.1l9.24,25.62,9.19-25.62h8.09L399,130.47Z"
                transform="translate(-17.49 -84.52)"
            />
            <path
                className={classnames(fill1ClassName)}
                d="M76.39,87.7a3.19,3.19,0,0,0-3.2-3.18H20.67a3.2,3.2,0,0,0-3.18,3.2l.15,51.21a3.19,3.19,0,0,0,3.2,3.19H73.36a3.2,3.2,0,0,0,3.18-3.2Zm-30.6,36.76a15.76,15.76,0,0,0,5-1,.4.4,0,0,1,.51.2,19.09,19.09,0,0,0,3.87,5.16c.23.31-.2.54-.2.54a19.5,19.5,0,0,1-9.17,2.26c-10.77.11-19.18-8.28-19.21-18.22A18,18,0,0,1,45.36,94.93a20.59,20.59,0,0,1,10.87,3.23c.72.46.71.68.55.9a41.69,41.69,0,0,0-3.06,5,.45.45,0,0,1-.68.15A13.75,13.75,0,0,0,45,102c-6.08,0-10.69,4.79-10.68,11.26A11.38,11.38,0,0,0,45.79,124.46Zm16.33.51a5.22,5.22,0,1,1,5.21-5.24A5.22,5.22,0,0,1,62.12,125Z"
                transform="translate(-17.49 -84.52)"
            />
        </svg>
    );
};

GovLogo.displayName = 'GovLogo';

GovLogo.propTypes = {
    fill1ClassName: PropTypes.string,
    fill2ClassName: PropTypes.string,
};

export default GovLogo;
