import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import shortId from '@concur/nui-widgets/lib/utils/shortId';

const SapLogo = (props) => {
    const {
        classNameMap,
        fill1ClassName,
        fill2ClassName,
        fill3ClassName,
        fill4ClassName,
        isFiori,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const idSuffix = shortId.generate();

    const gradient = isFiori ? (
        <linearGradient
            id="linear-gradient"
            x1="328.89"
            x2="328.89"
            y2="72"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#00b8f1" />
            <stop offset="0.02" stopColor="#01b6f0" />
            <stop offset="0.31" stopColor="#0d90d9" />
            <stop offset="0.58" stopColor="#1775c8" />
            <stop offset="0.82" stopColor="#1c65bf" />
            <stop offset="1" stopColor="#1e5fbb" />
        </linearGradient>
    ) : (
        <linearGradient
            id="linear-gradient"
            x1="36"
            x2="36"
            y2="35.62"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#00bbf6" />
            <stop offset="0.24" stopColor="#009fe2" />
            <stop offset="0.54" stopColor="#0084cf" />
            <stop offset="0.8" stopColor="#0074c3" />
            <stop offset="1" stopColor="#006ebf" />
        </linearGradient>
    );

    const content = isFiori ? (
        <g>
            <g>
                <path className={classnames(fill4ClassName)} d="M6.71,48.08V30H0V24.78H19.71V30H13V48.08Zm15.71-23.3h6.3v8.66h7.74V24.78h6.3v23.3h-6.3V38.66H28.72v9.42h-6.3ZM46.8,48.08V24.78H63.43V30H53.1v3.78h7.37V38.7H53.1V43H64v5.07Zm29.32,0V24.78h9.79c3.87,0,8.47.6,8.47,5.73A5,5,0,0,1,90,35.83v.06c2.83.51,5,1.86,5,5.73,0,5.07-4.72,6.46-8.65,6.46Zm6.17-14h3c2.26,0,3.11-.88,3.11-2.24s-.85-2.23-3-2.23h-3Zm0,9.29h3.15c2.36,0,3.36-.92,3.36-2.52s-1-2.43-3.43-2.43H82.29ZM98,48.08V24.78h16.63V30H104.33v3.78h7.37V38.7h-7.37V43h10.86v5.07ZM116.9,43l4.66-2.9a7.19,7.19,0,0,0,6.1,3.53c1.93,0,3.37-.57,3.37-2s-1.85-2-4.15-2.61c-3.81-1-8.88-2-8.88-7.24,0-4.76,3.59-7.37,8.88-7.37a12,12,0,0,1,9.76,4.57l-4.22,3.46a7.94,7.94,0,0,0-5.57-2.93c-1.8,0-2.71.6-2.71,1.7,0,1.54,1.82,2.11,4.12,2.68,3.81.91,8.91,2.07,8.91,7.3,0,3.71-2.61,7.4-9.79,7.4C122.06,48.52,118.88,46.13,116.9,43ZM145,48.08V30h-6.7V24.78H158V30h-6.71V48.08Zm23.78,0V24.78h9.1c6,0,9.63,2,9.63,7.59,0,3.65-1.54,5.76-4.6,6.8l5,8.91h-6.68L176.66,40h-1.73v8.09Zm6.17-12.88h2.71c2.58,0,3.71-.72,3.71-2.67s-1.13-2.65-3.71-2.65h-2.71Zm15.37,3.53V24.78h6.29V38.7c0,3.21,1,4.66,3.56,4.66s3.53-1.45,3.53-4.66V24.78h6.17V38.73c0,6.17-3,9.7-9.76,9.7S190.3,44.9,190.3,38.73Zm23.42,9.35V24.78h6.2l8.54,13.07h.06V24.78h5.76v23.3h-5.82l-8.91-13h-.07v13Z" />
                <polyline
                    className={classnames(fill1ClassName)}
                    points="256.12 72 329.68 72 401.67 0 256.12 0 256.12 72"
                />
                <path className={classnames(fill2ClassName)} d="M342.5,13.54H328.17V47.59L315.65,13.53H303.24L292.56,42c-1.14-7.19-8.57-9.67-14.42-11.53-3.85-1.24-7.95-3.06-7.91-5.07,0-1.66,2.19-3.19,6.48-3,2.89.15,5.43.38,10.49,2.82l5-8.66A38.82,38.82,0,0,0,276,12.77h0c-6.1,0-11.18,2-14.33,5.24a12.05,12.05,0,0,0-3.43,8.35,11.22,11.22,0,0,0,4.92,10,33.71,33.71,0,0,0,9.74,4.45c4,1.23,7.22,2.3,7.19,4.59a3.39,3.39,0,0,1-.95,2.24c-1,1-2.51,1.4-4.61,1.44-4.06.09-7.07-.55-11.87-3.38l-4.42,8.79a33.07,33.07,0,0,0,16.22,4.31h.74a19.62,19.62,0,0,0,12.33-4.14l.52-.45-1.43,3.84h13l2.18-6.65a23.55,23.55,0,0,0,7.65,1.22,23.93,23.93,0,0,0,7.47-1.15L319,58.06h21.22V44.3h4.63c11.19,0,17.81-5.7,17.81-15.24C362.62,18.42,356.19,13.54,342.5,13.54ZM309.38,42.69a13.11,13.11,0,0,1-4.59-.81l4.54-14.32h.09l4.46,14.36A13.39,13.39,0,0,1,309.38,42.69Zm34-8.24h-3.16V22.91h3.16c4.21,0,7.56,1.4,7.56,5.7s-3.35,5.84-7.56,5.84" />
                <path
                    className={classnames(fill3ClassName)}
                    d="M347.13,67.26a3.78,3.78,0,1,1,3.79,3.9A3.78,3.78,0,0,1,347.13,67.26Zm3.79,4.67a4.66,4.66,0,1,0-4.73-4.67A4.66,4.66,0,0,0,350.92,71.93Zm-1-4.3h.94L352.28,70h.92l-1.54-2.36a1.39,1.39,0,0,0,1.4-1.48c0-1-.63-1.52-1.89-1.52h-2.05V70h.81Zm0-.69V65.28H351c.56,0,1.16.11,1.16.78,0,.84-.61.88-1.29.88Z"
                />
            </g>
        </g>
    ) : (
        <>
            <polyline
                className={classnames(fill1ClassName)}
                points="0 35.62 36.39 35.62 72 0 0 0 0 35.62"
            />
            <path
                className={classnames(fill2ClassName)}
                d="M402.68,294.16H395.6V311l-6.19-16.85h-6.14L378,308.25c-.56-3.55-4.24-4.78-7.13-5.7-1.91-.61-3.94-1.52-3.92-2.51,0-.82,1.09-1.58,3.21-1.46a11.59,11.59,0,0,1,5.19,1.4l2.46-4.29a19.17,19.17,0,0,0-8-1.9h0a9.75,9.75,0,0,0-7.09,2.59,6,6,0,0,0-1.7,4.13,5.55,5.55,0,0,0,2.43,4.95,16.56,16.56,0,0,0,4.82,2.2c2,.61,3.57,1.14,3.56,2.27a1.67,1.67,0,0,1-.47,1.11,3.12,3.12,0,0,1-2.28.72,9.8,9.8,0,0,1-5.87-1.67L361,314.42a16.37,16.37,0,0,0,8,2.13h.37a9.68,9.68,0,0,0,6.1-2l.26-.22-.71,1.9h6.44l1.08-3.29a11.75,11.75,0,0,0,3.78.6,11.9,11.9,0,0,0,3.69-.57l1,3.25h10.5v-6.8h2.29c5.54,0,8.81-2.82,8.81-7.54C412.64,296.57,409.46,294.16,402.68,294.16ZM386.3,308.58a6.47,6.47,0,0,1-2.27-.4l2.25-7.09h0l2.21,7.11A6.66,6.66,0,0,1,386.3,308.58Zm16.8-4.07h-1.56v-5.71h1.56c2.08,0,3.74.69,3.74,2.82s-1.66,2.89-3.74,2.89"
                transform="translate(-359.95 -287.46)"
            />
            <path
                className={classnames(fill3ClassName)}
                d="M405,320.73a1.87,1.87,0,1,1,1.88,1.93A1.87,1.87,0,0,1,405,320.73Zm1.88,2.31a2.3,2.3,0,1,0-2.34-2.31A2.3,2.3,0,0,0,406.85,323Zm-.49-2.13h.46l.7,1.15H408l-.76-1.17a.69.69,0,0,0,.69-.73c0-.52-.31-.75-.94-.75h-1v2.66h.4Zm0-.34v-.82h.55c.28,0,.57.06.57.39s-.3.43-.64.43Z"
                transform="translate(-359.95 -287.46)"
            />
        </>
    );

    return (
        <svg
            id={`Sap_logo_${idSuffix}`}
            data-test="Sap_logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={isFiori ? '0 0 401.67 72' : '0 0 72 35.62'}
            role="img"
            {...otherProps}
        >
            <title>SAP</title>
            <defs>
                {gradient}
            </defs>
            {content}
        </svg>
    );
};

SapLogo.displayName = 'SapLogo';

SapLogo.propTypes = {
    fill1ClassName: PropTypes.string,
    fill2ClassName: PropTypes.string,
    fill3ClassName: PropTypes.string,
    fill4ClassName: PropTypes.string,
    isFiori: PropTypes.bool,
};

export default SapLogo;
