/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnamesBind from 'classnames/bind';
import shortId from '@concur/nui-widgets/lib/utils/shortId';
import Button from '@concur/nui-widgets/lib/Buttons/Button';
import Icon from '@concur/nui-widgets/lib/Icon/Icon';
import Overlay from '@concur/nui-widgets/lib/Overlay/Overlay';

const MenuItemToggle = React.forwardRef((props, ref) => {
    const {
        buttonAriaLabel,
        buttonClassName,
        buttonProps,
        children,
        className,
        classNameMap,
        content,
        cssBlock,
        hideCaret,
        onClick,
        onClose,
        overlayProps,
        popperPlacement,
        iconOnly,
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const [expanded, setExpanded] = useState(false);

    const buttonId = shortId.generate();

    const handleToggleClick = (e) => {
        setExpanded(!expanded);
        onClick(e, !expanded);
    };

    const handleClose = () => {
        setExpanded(false);
        onClose();
        document.getElementById(buttonId)?.focus();
    };

    const classes = classnames(`${cssBlock}__listitem`, className);

    const buttonClasses = classnames(
        `${cssBlock}__button`,
        buttonClassName,
        {
            [`${cssBlock}__button--icon`]: iconOnly,
            [`${cssBlock}__button--active`]: expanded,
        },
    );

    return (
        <li className={classes} ref={ref}>
            <Overlay
                {...overlayProps}
                disableEdgeDetection
                onHide={handleClose}
                popperPlacement={popperPlacement}
                show={expanded}
                targetComponent={(
                    <Button
                        {...buttonProps}
                        aria-expanded={expanded}
                        aria-haspopup="dialog"
                        aria-label={buttonAriaLabel}
                        className={buttonClasses}
                        id={buttonId}
                        innerTextClasses={iconOnly ? classnames(`${cssBlock}__button-inner-text`) : undefined}
                        onClick={handleToggleClick}
                    >
                        {content}
                        {!hideCaret && (
                            <Icon
                                ariaHidden
                                iconName="arrow-2-s"
                                forceSize="xs-plus"
                            />
                        )}
                    </Button>
                )}
                targetClassName={classnames(`${cssBlock}__target`)}
            >
                {children}
            </Overlay>
        </li>
    );
});

MenuItemToggle.displayName = 'MenuItemToggle';

MenuItemToggle.propTypes = {
    buttonAriaLabel: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonProps: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    overlayProps: PropTypes.object,
    cssBlock: PropTypes.string,
    hideCaret: PropTypes.bool,
    popperPlacement: PropTypes.string,
    iconOnly: PropTypes.bool,
};

MenuItemToggle.defaultProps = {
    onClick: () => {},
    onClose: () => {},
    popperPlacement: 'bottom-start',
};

export default MenuItemToggle;
