import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withThemeStyles } from '@concur/react-ui-theming';
import { withFormatter } from '@concur/nui-intl-runtime';
import { compose } from '@concur/core-ui-shell';
import Switch from '@concur/nui-widgets/lib/Switch/Switch';
import MessageBox from '@concur/nui-widgets/lib/MessageBox/MessageBox';
import QuickHelp from '@concur/nui-widgets/lib/Popover/QuickHelp';
import classnamesBind from 'classnames/bind';
import { saveThemeCookie } from '../utils/themeUtils';
import * as styles from './ThemeSwitcher-*.css';

const CSS_PREFIX = 'sapcnqr';
const CSS_BLOCK = 'sapcnqr-theme-switcher';

const ThemeSwitcher = React.forwardRef((props, ref) => {
    const {
        checked,
        className,
        classNameMap,
        formatter,
        isGateway,
        ...otherProps
    } = props;

    const [isChecked, setIsChecked] = useState(checked);
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e, state) => {
        setIsChecked(state);
        setShowModal(true);
    };

    const handleClose = () => {
        setIsChecked(!isChecked);
        setShowModal(false);
    };

    const handleContinue = () => {
        setShowModal(false);
        saveThemeCookie(isGateway ? 'sap_fiori_3' : 'concur_gateway');

        window.location.reload();
    };

    const classnames = classnamesBind.bind(classNameMap);

    const classes = classnames(CSS_PREFIX, CSS_BLOCK, className, {
        [`${CSS_BLOCK}--gateway`]: isGateway,
        [`${CSS_BLOCK}--fiori`]: !isGateway,
    });

    const quickHelp = (
        <QuickHelp
            className={classnames(CSS_PREFIX, `${CSS_BLOCK}__quickhelp`)}
            iconProps={{
                className: classnames(CSS_PREFIX, `${CSS_BLOCK}__icon`),
                iconName: 'messaging-info',
            }}
            message={formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.quickHelpDescription' })}
            placement={['bottom', 'bottom-end', 'bottom-start']}
            size="md"
            textClose={formatter.formattedMessage({ id: 'CoreUI.close' })}
            textShowQuickHelp={formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.quickHelpTextShow' })}
            title={formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.quickHelpTitle' })}
        />
    );

    return (
        <>
            <Switch
                {...otherProps}
                checked={isChecked}
                className={classes}
                inputProps={{
                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__input`),
                }}
                label={(
                    <>
                        {formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.label' })}
                        {/* only render QuickHelp for Gateway */}
                        {isGateway && quickHelp}
                    </>
                )}
                labelProps={{
                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__label`),
                }}
                onChange={handleChange}
                ref={ref}
                size="sm"
                stateLabels={{
                    active: formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.active' }),
                    inactive: formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.inactive' }),
                }}
                stateLabelProps={{
                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__state-label`),
                }}
                sliderProps={{
                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__slider`),
                }}
                handleProps={{
                    className: classnames(CSS_PREFIX, `${CSS_BLOCK}__handle`),
                }}
            />
            <MessageBox
                buttonNegative={{
                    text: formatter.formattedMessage({ id: 'CoreUI.cancel' }),
                    onClick: handleClose,
                }}
                buttonPrimary={{
                    text: formatter.formattedMessage({ id: 'Common.Continue' }),
                    onClick: handleContinue,
                    'data-test': 'theme-switcher-continue',
                }}
                closeButtonLabel={formatter.formattedMessage({ id: 'CoreUI.close' })}
                modalContainerClassName={classnames(CSS_PREFIX, `${CSS_BLOCK}__modal-container`)}
                onHide={handleClose}
                show={showModal}
                title={formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.modalTitle' })}
                type="confirm"
            >
                {formatter.formattedMessage({ id: 'CoreUI.themeSwitcher.modalBody' })}
            </MessageBox>
        </>
    );
});

ThemeSwitcher.displayName = 'ThemeSwitcher';

ThemeSwitcher.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    isGateway: PropTypes.bool,
};

ThemeSwitcher.defaultProps = {
};

export default compose(
    withThemeStyles(styles),
    withFormatter,
)(ThemeSwitcher);
