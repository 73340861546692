import classnamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import shortId from '@concur/nui-widgets/lib/utils/shortId';

const SapConcurLogo = (props) => {
    const {
        classNameMap,
        cssBlock,
        ...otherProps
    } = props;

    const classnames = classnamesBind.bind(classNameMap);

    const idSuffix = shortId.generate();
    const gradientSuffix = shortId.generate();

    return (
        <svg
            id={`SAPConcur_logo_${idSuffix}`}
            data-test="SAPConcur_logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 198.64 39.83"
            role="img"
            {...otherProps}
        >
            <title>SAP Concur</title>
            <defs>
                <linearGradient id={`SAP-${gradientSuffix}`} x1="40.26" y1="0" x2="40.26" y2="39.83" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#00b8f1" />
                    <stop offset=".02" stopColor="#00b5ef" />
                    <stop offset=".31" stopColor="#0d90d8" />
                    <stop offset=".58" stopColor="#1675c8" />
                    <stop offset=".82" stopColor="#1c64be" />
                    <stop offset="1" stopColor="#1e5fbb" />
                </linearGradient>
            </defs>
            <polyline style={{ fill: `url(#SAP-${gradientSuffix})`, fillRule: 'evenodd' }} points="0 39.83 40.69 39.83 80.52 0 0 0 0 39.83" />
            <path style={{ fill: '#fff', fillRule: 'evenodd' }} d="m47.78,7.49h-7.92s0,18.84,0,18.84l-6.92-18.84h-6.86l-5.91,15.76c-.63-3.97-4.74-5.35-7.97-6.37-2.14-.69-4.4-1.69-4.38-2.81.02-.92,1.22-1.77,3.59-1.64,1.6.08,3,.21,5.8,1.56l2.75-4.79c-2.56-1.3-6.09-2.12-8.98-2.12h-.02c-3.37,0-6.18,1.09-7.93,2.89-1.21,1.26-1.87,2.86-1.9,4.62-.04,2.43.85,4.16,2.72,5.53,1.58,1.16,3.61,1.91,5.39,2.47,2.2.68,4,1.27,3.98,2.54-.02.46-.19.89-.52,1.24-.55.57-1.39.78-2.55.8-2.24.05-3.91-.3-6.56-1.87l-2.45,4.86c2.64,1.51,5.78,2.39,8.97,2.39h.41c2.78-.06,5.03-.85,6.82-2.29.1-.08.2-.17.29-.25l-.8,2.12h7.2l1.21-3.67c1.26.43,2.7.67,4.23.67s2.89-.23,4.13-.63l1.16,3.64h11.74v-7.61h2.56c6.19,0,9.85-3.15,9.85-8.44,0-5.88-3.56-8.59-11.13-8.59Zm-18.32,16.12c-.92,0-1.79-.16-2.54-.44l2.51-7.93h.05l2.47,7.95c-.74.26-1.59.42-2.49.42Zm18.78-4.55h-1.75v-6.39h1.75c2.33,0,4.18.77,4.18,3.15s-1.86,3.24-4.18,3.24" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m80.13,19.79c0-8.67,5.56-12.76,12.61-12.76,4.29,0,7.5,1.5,9.75,4.08l-3.15,3.15c-1.42-1.85-3.45-3.08-6.58-3.08-4.89,0-7.79,2.99-7.79,8.6s2.9,8.62,7.79,8.62c3.13,0,5.16-1.23,6.58-3.08l3.15,3.15c-2.23,2.57-5.47,4.06-9.75,4.06-7.05,0-12.61-4.06-12.61-12.76Z" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m103.91,22.57c0-6.07,4.05-9.99,9.8-9.99s9.8,3.92,9.8,9.99-4.05,9.98-9.8,9.98-9.8-3.91-9.8-9.98Zm14.94,0c0-4.15-2.15-6.37-5.14-6.37s-5.14,2.22-5.14,6.37,2.15,6.37,5.14,6.37,5.14-2.23,5.14-6.37Z" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m126.97,12.94h4.31v4.05h.07c.88-2.2,2.64-4.42,6.32-4.42,4.13,0,5.98,2.81,5.98,6.91v12.7h-4.45v-11.63c0-2.59-.9-4.15-3.48-4.15s-4.29,1.76-4.29,5v10.78h-4.45V12.94Z" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m146.96,22.57c0-6.4,4.01-9.99,9.69-9.99,3.64,0,6.16,1.44,7.69,3.52l-2.85,2.52c-1.11-1.55-2.59-2.41-4.64-2.41-3.11,0-5.23,2.11-5.23,6.37s2.11,6.37,5.23,6.37c2.04,0,3.52-.88,4.64-2.43l2.85,2.53c-1.53,2.08-4.05,3.5-7.69,3.5-5.68,0-9.69-3.59-9.69-9.98Z" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m166.74,25.63v-12.68h4.47v11.63c0,2.57.88,4.15,3.47,4.15s4.31-1.78,4.31-5.01v-10.77h4.43v19.25h-4.29v-4.06h-.09c-.88,2.22-2.62,4.42-6.32,4.42-4.12,0-5.98-2.81-5.98-6.91Z" />
            <path className={classnames(`${cssBlock}__logo--concur`)} d="m188.1,12.94h4.31v4.13h.07c.65-3.08,2.09-4.5,4.5-4.5.7,0,1.3.12,1.65.25v4.17c-.47-.12-1.18-.23-1.85-.23-3.17,0-4.24,2.18-4.24,5.75v9.68h-4.45V12.94Z" />
        </svg>
    );
};

SapConcurLogo.displayName = 'SapConcurLogo';

SapConcurLogo.propTypes = {
    cssBlock: PropTypes.string,
};

export default SapConcurLogo;
